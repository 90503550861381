<template>
  <div :class="$style.group">
    <div :class="$style.title">
      <span v-if="titleShow">{{ type | typeName }}分组</span>
      <i
        v-if="edit"
        v-permission="'content.material.manage-group.create'"
        :class="$style.add"
        class="ri-add-circle-line"
        @click="modalAdd()"
      />
    </div>
    <div
      v-for="item in groupDefaultData"
      :key="item.id"
      :class="[$style.item, active === item.id ? $style.active : null]"
      @click="onClick(item.id)"
    >
      <i :class="$style.icon" class="ri-folder-3-line" />
      <span>{{ item.name }}</span>
    </div>
    <div :class="$style.line" />
    <div v-if="data.length">
      <div v-for="(item, index) in data" :key="item.id">
        <div :class="[$style.item, active === item.id ? $style.active : null]" @click.self="onClick(item.id)">
          <i :class="$style.icon" class="ri-folder-3-line" @click="onClick(item.id)" />
          <span :class="$style.itemName" class="overflow" :title="item.name" @click="onClick(item.id)">{{
            item.name
          }}</span>
          <span :class="$style.more">
            <i
              v-if="item.visible && item.children.length"
              class="ri-arrow-down-s-line"
              @click="childrenChange(index, false)"
            />
            <i
              v-if="!item.visible && item.children.length"
              class="ri-arrow-right-s-line"
              @click="childrenChange(index, true)"
            />
            <a-dropdown v-if="edit" :class="$style.moreBtn" :trigger="['click']">
              <i class="ri-more-line primary" />
              <a-menu slot="overlay">
                <a-menu-item v-if="item.children">
                  <Bj-link
                    v-permission="'content.material.manage-group.create'"
                    :class="$style.moreItem"
                    @click="modalAdd(item.id)"
                  >
                    <i :class="$style.moreItemIcon" class="ri-add-circle-line" />
                    添加分组
                  </Bj-link>
                </a-menu-item>
                <a-menu-item>
                  <Bj-link
                    v-permission="'content.material.manage-group.edit'"
                    :class="$style.moreItem"
                    @click="modalRename(item)"
                  >
                    <i :class="$style.moreItemIcon" class="ri-edit-2-line" />
                    重命名
                  </Bj-link>
                </a-menu-item>
                <a-menu-item>
                  <Bj-link
                    v-permission="'content.material.manage-group.delete'"
                    :class="$style.moreItem"
                    @click="modalDelete(item.id)"
                  >
                    <i :class="$style.moreItemIcon" class="ri-delete-bin-7-line" />
                    删除分组
                  </Bj-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </div>
        <div v-if="item.visible">
          <div
            v-for="children in item.children"
            :key="children.id"
            :class="[$style.childrenItem, active === children.id ? $style.active : null]"
            @click.self="onClick(children.id)"
          >
            <i :class="$style.icon" class="ri-folder-3-line" @click="onClick(children.id)" />
            <span :class="$style.itemName" class="overflow" :title="item.name" @click="onClick(children.id)">{{
              children.name
            }}</span>
            <span v-if="edit" :class="$style.more">
              <a-dropdown :trigger="['click']">
                <i class="ri-more-line primary" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <Bj-link
                      v-permission="'content.material.manage-group.edit'"
                      :class="$style.moreItem"
                      @click="modalRename(children)"
                    >
                      <i :class="$style.moreItemIcon" class="ri-edit-2-line" />
                      重命名
                    </Bj-link>
                  </a-menu-item>
                  <a-menu-item>
                    <Bj-link
                      v-permission="'content.material.manage-group.delete'"
                      :class="$style.moreItem"
                      @click="modalDelete(children.id)"
                    >
                      <i :class="$style.moreItemIcon" class="ri-delete-bin-7-line" />
                      删除分组
                    </Bj-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </div>
        </div>
      </div>
    </div>

    <bj-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="loading"
      :width="520"
      :body-style="{ height: '390px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <Bj-form v-if="modalType === 'add'" ref="add">
        <Bj-input
          v-model="form.add"
          label-align="left"
          v-bind="layout"
          rules="required"
          placeholder="请输入分组名称"
          label="分组名称"
        />
      </Bj-form>
      <Bj-form v-if="modalType === 'rename'" ref="rename">
        <Bj-input
          v-model="form.rename"
          label-align="left"
          placeholder="请输入分组名称"
          v-bind="layout"
          rules="required"
          label="分组名称"
        />
      </Bj-form>
    </bj-modal>
  </div>
</template>

<script>
// import _ from 'lodash'

import { materialService } from '@/service'
import { getGroupDefault } from '@/utils/variable'

const service = new materialService()

export default {
  name: 'group',
  filters: {
    typeName(val) {
      switch (val) {
        case 'image':
          return '图片'
        case 'video':
          return '视频'
        case 'audio':
          return '音频'
      }
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    active: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    titleShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      groupDefaultData: [],
      data: [],
      modalTitle: '',
      visible: false,
      loading: false,
      modalType: '',
      form: {
        add: null,
        parent_id: null,
        rename: null,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  created() {
    this.getGroup()
    this.groupDefaultData = getGroupDefault(this.type)
  },
  methods: {
    async getGroup() {
      const { data } = await service.getGroup({ material_type: this.type })
      this.data = data
      this.data.map(item => {
        item.visible = false
      })
    },
    onClick(_id) {
      if (_id === this.active) return
      this.$emit('change', _id)
    },
    modalAdd(id) {
      this.modalType = 'add'
      this.modalTitle = '添加分组'
      this.visible = true
      this.form.parent_id = id ? id : ''
    },
    modalRename(item) {
      this.form.rename = item.name
      this.modalTitle = '重命名'
      this.modalType = 'rename'
      this.form.id = item.id
      this.visible = true
    },
    async add() {
      try {
        await service.addGroup({
          material_type: this.type,
          name: this.form.add,
          parent_id: this.form.parent_id,
        })
        this.$message.success('添加成功')
      } catch (error) {
        this.handleCancel()
      } finally {
        this.refresh()
        this.handleCancel()
      }
    },
    async rename() {
      try {
        await service.rename({
          id: this.form.id,
          name: this.form.rename,
        })
        this.$message.success('修改成功')
      } catch (error) {
        this.handleCancel()
      } finally {
        this.refresh()
        this.handleCancel()
      }
    },
    async delete() {
      try {
        await service.deleteGroup({
          id: this.form.id,
        })
      } catch (e) {
      } finally {
        this.refresh()
      }
    },
    modalDelete(id) {
      this.form.id = id
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        content: '确定要删除分组？该分组下如果有素材，素材将移动至默认分组。',
        onOk() {
          _this.delete()
        },
      })
    },
    handleCancel() {
      this.visible = false
      this.form = {
        add: null,
        parent_id: null,
        rename: null,
      }
    },
    handleOk() {
      switch (this.modalType) {
        case 'add':
          this.add()
          break
        case 'rename':
          this.rename()
          break
      }
    },
    childrenChange(index, type) {
      this.data.splice(index, 1, { ...this.data[index], visible: type })
    },
    refresh() {
      this.getGroup()
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="less" module>
.group {
  height: 100%;
  padding-right: 20px;
  color: #000;
  border-right: 1px solid #eee;
  user-select: none;

  .title {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .add {
    float: right;
    color: @primary-color;
    font-size: 20px;
    cursor: pointer;
  }

  .item {
    width: 200px;
    height: 44px;
    margin: 10px 0;
    padding: 6px 12px 12px;
    border-radius: 6px;
    cursor: pointer;
  }

  .active {
    color: @primary-color;
    background: #fafafa;
  }

  .line {
    width: 180px;
    height: 1px;
    margin: 15px auto;
    border-top: 1px solid #eee;
  }

  .icon {
    margin-right: 10px;
    font-size: 20px;
    vertical-align: -4px;
  }

  .more {
    float: right;

    i {
      font-size: 20px;
    }

    &-item {
      color: #000;
    }

    &-btn {
      display: none;
    }
  }

  .item:hover .more-btn {
    display: inline-block;
  }
}

.more-item-icon {
  // position: relative;
  // top: 2px;
}

.item-name {
  position: relative;
  top: 6px;
  display: inline-block;
  width: 100px;
}

.children-item {
  width: 200px;
  height: 44px;
  padding: 6px 12px 12px 32px;
  cursor: pointer;
}
</style>
