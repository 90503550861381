<template>
  <bj-modal
    id="uploadModal"
    :class="$style.update"
    :title="title"
    :visible="visible"
    :width="520"
    :footer="false"
    @cancel="cancel"
  >
    <BjForm>
      <BjValidationItem
        rules="required"
        label="所属分组"
        label-align="left"
        v-bind="layout"
      >
        <bj-cascader
          v-model="groupId"
          :options="groupData"
          :field-names="{
            label: 'name',
            value: 'id',
            children: 'children',
          }"
          :change-on-select="true"
          :show-search="true"
          placeholder="请选择所属分组"
          :allow-clear="false"
        />
      </BjValidationItem>
    </BjForm>
    <div :class="$style.box">
      <a-upload-dragger
        name="file"
        :multiple="multiple"
        :custom-request="customRequest"
        :before-upload="beforeUpload"
        :accept="accept"
      >
        <p class="ant-upload-drag-icon">
          <i :class="$style.icon" class="ri-add-line" />
        </p>
        <p class="ant-upload-text">{{ titleTip[type] }}</p>
        <p v-if="!returnChange" class="ant-upload-hint">
          {{ describeTip[type] }}
        </p>
        <p v-else class="ant-upload-hint">
          {{ describeTipChange[type] }}
        </p>
      </a-upload-dragger>
    </div>
    <template #footer>
      <BjButton type="default" @click="check()">查看任务</BjButton>
    </template>
  </bj-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { materialService } from "@/service";

const mService = new materialService();

export default {
  name: "uploadModal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    // 是否change事件返回
    returnChange: {
      type: Boolean,
      default: false,
    },
    // 素材中心选了哪个分组给默认哪个
    defaultGroup: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "上传",
      groupId: ["default"],
      groupData: [],
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      titleTip: {
        image: "请手动选择或拖入图片",
        video: "请手动选择或拖入视频",
        audio: "请手动选择或拖入音频",
      },
      describeTip: {
        image:
          "图片大小不超过2M；支持JPG、JPEG、PNG、GIF、BMP格式；一次最多可上传20个文件",
        video:
          "视频大小不超过2G；支持MP、AVI、WMV、MOV、FLV、RMVB、M4V、MKV格式；一次最多可上传1个文件",
        audio:
          "音频大小不超过500M；支持MP3、WAV、FLAC、MID、MOV、M4A格式；一次最多可上传5个文件",
      },
      describeTipChange: {
        image:
          "图片大小不超过2M；支持JPG、JPEG、PNG、GIF、BMP格式；一次最多可上传1个文件",
        video:
          "视频大小不超过2G；支持MP、AVI、WMV、MOV、FLV、RMVB、M4V、MKV格式；一次最多可上传1个文件",
        audio:
          "音频大小不超过500M；支持MP3、WAV、FLAC、MID、MOV、M4A格式；一次最多可上传1个文件",
      },
      index: 0,
    };
  },
  computed: {
    ...mapState(["token", "uploadVisible"]),
    accept() {
      if (this.type === "image") {
        return "image/jpg, image/jpeg, image/png, image/gif, image/bmp";
      }
      if (this.type === "video") {
        return "video/*";
      }
      if (this.type === "audio") {
        return "audio/m4a, audio/mp3, audio/wav, audio/flac, audio/mid, audio/mov";
        // return 'audio/*'
      }
      return "";
    },
    multiple() {
      if (this.type === "image") {
        return true;
      } else if (this.type === "video") {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getGroup();
        switch (this.type) {
          case "image":
            this.title = "上传图片";
            break;
          case "video":
            this.title = "上传视频";
            break;
          case "audio":
            this.title = "上传音频";
        }
        if (this.defaultGroup) {
          this.groupId = [this.defaultGroup];
        } else {
          this.groupId = ["default"];
        }
      }
    },
  },
  methods: {
    ...mapActions(["setFile", "initOss"]),
    ...mapMutations(["UPLOAD_VISIBLE"]),
    init(filename, file) {
      this.initOss({
        type: this.type,
        group_id: this.groupId[this.groupId.length - 1],
        filename: filename,
        file: file,
        size: file.size,
      });
      this.cancel();
    },
    beforeUpload(file, fileList) {
      if (!this.returnChange) {
        if (file.size > 1024 * 1024 * 2 && this.type === "image") {
          this.$message.error(
            "当前选择的文件大小超过限制，请按照上传规定的要求处理后重新上传。"
          );
          return false;
        }
        if (file.size > 1024 * 1024 * 1024 * 2 && this.type === "video") {
          this.$message.error(
            "当前选择的文件大小超过限制，请按照上传规定的要求处理后重新上传。"
          );
          return false;
        }
        if (file.size > 1024 * 1024 * 500 && this.type === "audio") {
          this.$message.error(
            "当前选择的文件大小超过限制，请按照上传规定的要求处理后重新上传。"
          );
          return false;
        }
        if (fileList.length > 5 && this.type === "audio") {
          this.index++;
          if (this.index === 5) {
            this.$message.error(
              "当前选择的文件数量超过限制，未超过部分会正常上传，超出部分会被禁止，请再次上传。"
            );
          }
          if (this.index > 5) {
            return false;
          }
        }
        if (fileList.length > 20 && this.type === "image") {
          this.index++;
          if (this.index === 20) {
            this.$message.error(
              "当前选择的文件数量超过限制，未超过部分会正常上传，超出部分会被禁止，请再次上传。"
            );
          }
          if (this.index > 20) {
            return false;
          }
        }
      } else {
        if (file.size > 1024 * 1024 * 2 && this.type === "image") {
          this.$message.error(
            "当前选择的文件大小超过限制，请按照上传规定的要求处理后重新上传。"
          );
          return false;
        }
        if (file.size > 1024 * 1024 * 1024 * 2 && this.type === "video") {
          this.$message.error(
            "当前选择的文件大小超过限制，请按照上传规定的要求处理后重新上传。"
          );
          return false;
        }
        if (file.size > 1024 * 1024 * 500 && this.type === "audio") {
          this.$message.error(
            "当前选择的文件大小超过限制，请按照上传规定的要求处理后重新上传。"
          );
          return false;
        }
        if (fileList.length > 1) {
          this.$message.error("当前选择的文件数量超过限制。");
          return false;
        }
      }
    },
    async customRequest(options) {
      document.querySelector("#uploadModal .ant-modal").style.transformOrigin =
        "center center";
      setTimeout(() => {
        this.$store.commit("CAR", true);
      }, 200);
      if (this.type === "image") {
        const config = {
          headers: { "Content-Type": "multipart/form-data", Authorization: this.token },
        };
        this.setFile({
          config,
          name: options.file.name,
          type: this.type,
          file: options.file,
          group_id: this.groupId[this.groupId.length - 1],
        });
        this.cancel();
        if (this.returnChange) {
          this.getAllData(options.file.uid);
        }
      } else {
        // this.init(options.file.name, options.file)
        const config = {
          headers: { "Content-Type": "multipart/form-data", Authorization: this.token },
        };
        this.setFile({
          config,
          name: options.file.name,
          type: this.type,
          file: options.file,
          group_id: this.groupId[this.groupId.length - 1],
        });
        if (this.returnChange) {
          this.getAllData(options.file.uid);
        }
      }
    },
    getAllData(_uid) {
      const tmp = this.$store.state.uploadFile.filter((item) => item.uid === _uid);
      if (tmp.length && tmp[0].path) {
        this.$emit("change", tmp);
      } else {
        setTimeout(() => {
          this.getAllData(_uid);
        }, 100);
      }
    },
    async getGroup() {
      const { data } = await mService.getGroup({ material_type: this.type });
      this.groupData = [
        {
          id: "default",
          name: "默认分组",
        },
      ].concat(
        data.map((item) => {
          if (item.children.length) {
            item.isLeaf = false;
          }
          return item;
        })
      );
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    check() {
      this.$emit("update:visible", false);
      this.$store.commit("UPLOAD_VISIBLE", true);
    },
  },
};
</script>

<style lang="less" module>
.update {
  .box {
    height: 300px;

    .icon {
      color: #5c5c5c;
      font-size: 80px;
    }
  }
}
</style>
